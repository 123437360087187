import React from "react";
import { Images, Logos } from "../constants";
import LoginForm from "../components/form/LoginForm";

const Login = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={Logos.LogoName} alt="" />
      <div className="w-1/4 mt-10">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
