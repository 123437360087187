import React, { useState } from "react";
import { Icons } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Spinner } from "@chakra-ui/react";
import authService from "../../services/authService";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const navigate = useNavigate();

  const { register, handleSubmit, reset } = useForm({});
  const onSubmit = (data) => {
    setLoading(true);
    authService
      .login(data)
      .then((res) => {
        localStorage.setItem("id", res.id);
        localStorage.setItem("token", res.token);
        localStorage.setItem("role", res.role);
        reset();
        navigate("/");
      })
      .catch((err) => {
        setErr(err.message);
        console.log(err.message);
      });
    setLoading(false);
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <p className="text-red-600 bg-red-100 px-2 rounded border border-red-600 mb-4">
        {err}
      </p>
      <input
        type="text"
        placeholder="email"
        {...register("email", { required: true })}
        className="mb-6 block w-full border rounded-lg border-gray-400 p-4 outline-none text-xs md:text-base"
      />
      <input
        type="password"
        name="password"
        id=""
        {...register("password", { required: true })}
        placeholder="Password"
        className="mb-4 md:mb-10 block w-full border rounded-lg p-4 border-gray-400 outline-none text-xs md:text-base"
      />
      <div className="flex items-center justify-center">
        {loading ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <input
            type="submit"
            value="Log In"
            className="text-white capitalize font-medium bg-third-100 py-2 md:py-4 px-10 cursor-pointer text-sm md:text-base rounded-xl"
          />
        )}
      </div>
    </form>
  );
};

export default LoginForm;
