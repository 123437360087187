import React, { useState } from "react";
import InputCustom from "../ui/InputCustom";
import { Select } from "@chakra-ui/react";
import categoriesService from "../../services/categoriesService";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import UploadImages from "../ui/UploadImages";
import { Spinner } from "@chakra-ui/react";

const CategoriesAddForm = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);

  // add new data
  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      icon: null,
    },
  });
  const onSubmit = (data) => {
    setLoading(true);
    categoriesService
      .add(sessionStorage.getItem("token"), data)
      .then((res) => {
        toast({
          title: "Category created.",
          description: "We've created your category for you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        reset();
        setSelectedImages(null);
        setUploadProgress(null);
        setLoading(false);
      })
      .catch((err) => {
        toast({
          title: "Error category created.",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <div className="bg-white rounded-lg p-6">
      <h3 className="capitalize text-xl text-secondary-100">
        ajouter nouveau category
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mt-4">
        <div>
          <h3 className="mb-1 font-medium first-letter:capitalize">
            name category en arabe
          </h3>
          <InputCustom
            placeholder={"saisir le nom du category en arabe"}
            field={register("nameAR", {
              required: true,
            })}
          />
        </div>
        <div>
          <h3 className="mb-1 font-medium first-letter:capitalize">
            name category en francais
          </h3>
          <InputCustom
            placeholder={"saisir le nom du category en francais"}
            field={register("nameFR", {
              required: true,
            })}
          />
        </div>
        <div>
          <h3 className="mb-1 font-medium first-letter:capitalize">
            name category en anglais
          </h3>
          <InputCustom
            placeholder={"saisir le nom du category en anglais"}
            field={register("nameEN", {
              required: true,
            })}
          />
        </div>
        <div className="col-span-2 space-y-2">
          <div>
            <h3 className="mb-1 font-medium first-letter:capitalize">image</h3>
            <UploadImages
              setValue={setValue}
              getValues={getValues}
              selectedImages={selectedImages}
              setSelectedImages={setSelectedImages}
              uploadProgress={uploadProgress}
              setUploadProgress={setUploadProgress}
            />
          </div>
        </div>
        <div className="space-x-4 flex items-center justify-end pt-10">
          <Link
            to={"../"}
            className="cursor-pointer bg-secondary-100 rounded-lg px-8 py-2 text-white font-medium capitalize"
          >
            cancel
          </Link>
          {loading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <input
              type="submit"
              value="ajouter"
              className="cursor-pointer bg-primary-100 rounded-lg px-8 py-2 text-white font-medium capitalize"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default CategoriesAddForm;
