import { configureStore } from "@reduxjs/toolkit";
import productSlice from "./product/product-slice";
// import { filterActions } from "./filter/filter-slice";

const store = configureStore({
  reducer: {
    product: productSlice.reducer,
    // filter: filterActions.reducer,
  },
});

export default store;
