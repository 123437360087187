import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useToast,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Switch } from "@chakra-ui/react";
import { Icons } from "../../constants";
import { useQuery } from "react-query";
import categoriesService from "../../services/categoriesService";
import SearchForm from "../form/SearchForm";
import newsService from "../../services/newsService";
import FilterBar from "../../layout/FilterBar";
import { Select } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

const NewsTable = () => {
  const keys = ["image", "title AR", "title FR", "title EN", "date event"];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails,
  } = useDisclosure();
  const [selected, setSelected] = useState(null);
  const toast = useToast();

  // get query
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");

  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);

  // go to another page
  const navigate = useNavigate();
  const navigateAddSection = () => {
    navigate("./add");
  };

  // load data of news
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "news",
    () =>
      newsService.getAll(sessionStorage.getItem("token"), {
        query: query,
      })
  );

  useEffect(() => {
    refetch();
  }, [query]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  // conver date to format string
  const convertDateFormat = (date) => {
    const d = new Date(date);
    const formattedDate = d
      .toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
      .replace(",", "");

    return formattedDate;
  };

  // delete item
  const handleDelete = () => {
    setLoading(true);
    newsService
      .remove(sessionStorage.getItem("token"), selected)
      .then((res) => {
        toast({
          title: "New deleted.",
          description: "We've deleted your new for you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refetch();
        setSelected(null);
        onClose();
        setLoading(false);
      })
      .catch((err) => {
        toast({
          title: "Error new deleted.",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  // toggle status
  const toggleStatus = (id) => {
    setLoadingId(id);
    setLoading(true);
    newsService
      .toggleStatus(sessionStorage.getItem("token"), id)
      .then((res) => {
        toast({
          title: "News updated.",
          description: "We've change status news for you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refetch();
        setLoading(false);
        setLoadingId(null);
      })
      .catch((err) => {
        toast({
          title: "Error news updated.",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        setLoadingId(null);
      });
  };

  // toggle status
  const handleClearFilter = () => {
    setCategory("");
    setEndDate("");
    setStartDate("");
    setStatus("");
    refetch();
  };

  return (
    <div>
      <TableContainer className="w-full">
        <Table variant="simple" className="w-full">
          <Thead>
            <Tr className="text-left uppercase text-xs font-semibold">
              <Th className="">#</Th>
              {keys.map((key) => (
                <Th key={key}>{key}</Th>
              ))}
              <Th className=" ">status</Th>
              <Th isNumeric className="text-right ">
                <p className="mb-2 mr-2">...</p>
              </Th>
            </Tr>
          </Thead>
          <Tbody className="text-sm">
            {data.map((item, idx) => {
              return (
                <Tr className="border-t border-gray-500" key={item.id}>
                  <Td>{idx + 1}</Td>
                  <Td className="py-3">
                    <div className="flex items-center border h-10 w-10 rounded-full overflow-hidden">
                      <img
                        src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${item.image}`}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </Td>
                  <Td>
                    <p className="truncate w-[100px] col-span-2">
                      {item.titleAR}
                    </p>
                  </Td>
                  <Td>
                    <p className="truncate w-[100px] col-span-2">
                      {item.titleFR}
                    </p>
                  </Td>
                  <Td>
                    <p className="truncate w-[100px] col-span-2">
                      {item.titleEN}
                    </p>
                  </Td>
                  <Td>
                    <p>{convertDateFormat(item.dateEvent)}</p>
                  </Td>
                  <Td className="">
                    {loading && loadingId === item.id ? (
                      <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="md"
                      />
                    ) : (
                      <Switch
                        size="sm"
                        isChecked={item.status === "ACTIVE"}
                        onChange={() => toggleStatus(item.id)}
                      />
                    )}
                  </Td>
                  <Td>
                    <div className="buttons flex justify-end items-center space-x-3">
                      <button
                        className="bg-secondary-100 rounded-full w-[30px] h-[30px] flex items-center justify-center"
                        onClick={() => {
                          setSelected(item);
                          onOpenDetails();
                        }}
                      >
                        <img src={Icons.Eye} alt="" className="w-[17px]" />
                      </button>
                      <Link to={`./${item.id}`}>
                        <button className="bg-secondary-100 rounded-full w-[30px] h-[30px] flex items-center justify-center">
                          <img src={Icons.Edit} alt="" className="w-[17px]" />
                        </button>
                      </Link>
                      <button
                        className="bg-secondary-100 rounded-full w-[30px] h-[30px] flex items-center justify-center"
                        onClick={() => {
                          setSelected(item.id);
                          onOpen();
                        }}
                      >
                        <img src={Icons.Bin} alt="" className="w-[17px]" />
                      </button>
                    </div>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <AlertBox
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
        loading={loading}
      />

      {selected && (
        <ModalDetails
          isOpen={isOpenDetails}
          onClose={() => {
            onCloseDetails();
            setSelected(null);
          }}
          selected={selected}
        />
      )}
    </div>
  );
};

export default NewsTable;

const AlertBox = ({ isOpen, onClose, handleDelete, loading }) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      // leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete New
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          {loading ? (
            <AlertDialogFooter className="space-x-4">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </AlertDialogFooter>
          ) : (
            <AlertDialogFooter className="space-x-4">
              <button
                onClick={onClose}
                className="border border-third-100 text-third-100 font-medium px-6 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="bg-third-100 text-white font-medium px-6 py-2 rounded-lg"
              >
                Delete
              </button>
            </AlertDialogFooter>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const ModalDetails = ({ isOpen, onClose, selected }) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      // leastDestructiveRef={cancelRef}
      onClose={onClose}
      size={"3xl"}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Detail News
          </AlertDialogHeader>

          <AlertDialogBody size={"xl"}>
            <div className="">
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">
                  titre en arabe:
                </p>
                <p className="col-span-2">{selected.titleAR}</p>
              </div>
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">
                  titre en francais:
                </p>
                <p className="col-span-2">{selected.titleFR}</p>
              </div>
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">
                  titre en anglais:
                </p>
                <p className="col-span-2">{selected.titleEN}</p>
              </div>
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">
                  date d'event:
                </p>
                <p className="col-span-2">{selected.dateEvent}</p>
              </div>
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">
                  description en arabe:
                </p>
                <p className="col-span-2">{selected.despAR}</p>
              </div>
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">
                  description en francais:
                </p>
                <p className="col-span-2">{selected.despFR}</p>
              </div>
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">
                  description en anglais:
                </p>
                <p className="col-span-2">{selected.despEN}</p>
              </div>
            </div>
          </AlertDialogBody>

          <AlertDialogFooter className="space-x-4">
            <button
              onClick={onClose}
              className="border border-third-100 text-third-100 font-medium px-6 py-2 rounded-lg"
            >
              Fermer
            </button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
