import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./layout/ScrollToTop";
import CategoryPage from "./pages/CategoryPage";
import CategoriesTable from "./components/section/CategoriesTable";
import CategoriesAddForm from "./components/form/CategoriesAddForm";
import CategoriesEditForm from "./components/form/CategoriesEditForm";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import TalksPage from "./pages/TalksPage";
import TalksTable from "./components/section/TalksTable";
import NewPage from "./pages/NewPage";
import NewsTable from "./components/section/NewsTable";
import NewsAddForm from "./components/form/NewAddForm";
import StartupPage from "./pages/StartupPage";

function App() {
  const { t } = useTranslation("global");

  useEffect(() => {
    sessionStorage.setItem(
      "token",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWM5MTNiZTZiNDFiODgxNWNjNDE4OWEiLCJpYXQiOjE3MDc3NjMyMDN9.tdw7dOUNxNtUt1F-ZY68rtkDxuQGsihI2BaDq1WAAWg"
    );
  }, []);
  return (
    <div className="h-fit">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route path="/" element={<HomePage />}>
            <Route path="talks" element={<TalksPage />}>
              <Route index element={<TalksTable />} />
            </Route>
            <Route path="startups" element={<StartupPage />}>
              <Route index element={<CategoriesTable />} />
              <Route path="add" element={<CategoriesAddForm />} />
              <Route path=":id" element={<CategoriesEditForm />} />
            </Route>
            <Route path="categories" element={<CategoryPage />}>
              <Route index element={<CategoriesTable />} />
              <Route path="add" element={<CategoriesAddForm />} />
              <Route path=":id" element={<CategoriesEditForm />} />
            </Route>
            <Route path="news" element={<NewPage />}>
              <Route index element={<NewsTable />} />
              <Route path="add" element={<NewsAddForm />} />
              {/* <Route path=":id" element={<CategoriesEditForm />} /> */}
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
