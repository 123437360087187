import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const filterParams = (filters) => {
  return Object.entries(filters)
    .filter(([key, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
};

const getAll = (token, filters) => {
  return axios
    .get(`${url}/news/all?${filterParams(filters)}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

const add = (token, data) => {
  return axios
    .post(`${url}/news`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

const toggleStatus = (token, id) => {
  return axios
    .patch(`${url}/news/${id}/toggle`, null, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

const update = (token, id, data) => {
  return axios
    .patch(`${url}/courses/${id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

const remove = (token, id) => {
  return axios
    .delete(`${url}/news/${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export default {
  getAll,
  add,
  update,
  toggleStatus,
  remove,
};
