import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../layout/SideBar";

const HomePage = () => {
  return (
    <div className="h-screen grid grid-cols-10 gap-x-8">
      <div className="col-span-2">
        <SideBar />
      </div>
      <div className="col-span-8 py-6 pr-6">
        <Outlet />
      </div>
    </div>
  );
};

export default HomePage;
