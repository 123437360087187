import React, { useState } from "react";
import InputCustom from "../ui/InputCustom";
import { Select } from "@chakra-ui/react";
import categoriesService from "../../services/categoriesService";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";

const CategoriesEditForm = () => {
  const toast = useToast();

  // get id to check if we are in edit page
  const { id } = useParams();

  // update data
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    categoriesService
      .update(id, data)
      .then((res) => {
        toast({
          title: "Category updated.",
          description: "We've updated your category for you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refetch();
      })
      .catch((err) =>
        toast({
          title: "Error category updated.",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      );
  };

  // load data category Id
  const {
    isLoading: isLoadingCat,
    data: dataCat,
    isError: isErrorCat,
    error: errorCat,
  } = useQuery("category", () => categoriesService.get(id));
  // load data
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "categories",
    () => categoriesService.getAll({})
  );

  if (isLoading || isLoadingCat) {
    return <div>Loading...</div>;
  }
  if (isErrorCat) return <div>Error: {errorCat.message}</div>;
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h3 className="capitalize text-xl">modifier nouveau category</h3>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mt-4">
        <Select
          placeholder="choisir category parent"
          defaultValue={dataCat.id}
          className="placeholder:capitalize"
          {...register("parent_category_id")}
        >
          <option value={null}>- no parent -</option>
          {data.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
        <InputCustom
          placeholder={"saisir le nom du category"}
          field={register("name", {
            required: true,
          })}
          defaultValue={dataCat.name}
        />
        <InputCustom
          placeholder={"choisir image"}
          field={register("icon", { required: true })}
          defaultValue={dataCat.icon}
        />
        <div className="space-x-4 flex items-center justify-end pt-10">
          <Link
            to={"../"}
            className="cursor-pointer bg-secondary-100 rounded-lg px-8 py-2 text-white font-medium capitalize"
          >
            cancel
          </Link>
          <input
            type="submit"
            value={"sauvegarder"}
            className="cursor-pointer bg-primary-100 rounded-lg px-8 py-2 text-white font-medium capitalize"
          />
        </div>
      </form>
    </div>
  );
};

export default CategoriesEditForm;
