import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const login = (data) => {
  return axios
    .post(`${url}/login`, data)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.message);
    });
};

export default { login };
