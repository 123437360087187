import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SearchForm from "../components/form/SearchForm";
import { Images } from "../constants";

const CategoryPage = () => {
  // go to another page
  const navigate = useNavigate();
  const navigateAddSection = () => {
    navigate("./add");
  };

  const onSubmit = (data) => {
    if (data.search) navigate(`/categories?query=${data.search}`);
    else navigate("/categories");
  };

  return (
    <div>
      <div className="grid grid-cols-4 mb-10">
        <h1 className="text-primary-100 font-medium text-3xl capitalize">
          Categories
        </h1>
        <div className="col-span-2">
          <SearchForm onSubmit={onSubmit} />
        </div>
        <div className="flex items-center justify-end space-x-3">
          <div className="flex items-center justify-between">
            <button
              onClick={navigateAddSection}
              className="!h-full rounded-lg capitalize font-medium flex items-center py-2 bg-primary-100 text-white space-x-2 px-4"
            >
              ajouter
            </button>
          </div>
          <div className="rounded-full text-center w-10 h-10 flex items-center justify-center">
            <img src={Images.Avatar} alt="" />
          </div>
        </div>
      </div>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default CategoryPage;
