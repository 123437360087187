import Love from "../assets/icons/heart small.svg";
import Cart from "../assets/icons/Cart1.svg";
import Eye from "../assets/icons/show.svg";
import Edit from "../assets/icons/edit.svg";
import Bin from "../assets/icons/bin.svg";
import Search from "../assets/icons/Component 2.svg";
import ArrowRight from "../assets/icons/icons arrow-right.svg";
import ArrowLeft from "../assets/icons/icons_arrow-left.svg";
import ArrowRight2 from "../assets/icons/rightArrow.svg";
import StarActive from "../assets/icons/star-active.svg";
import Star from "../assets/icons/star.svg";
import DropDown from "../assets/icons/DropDown.svg";
import Plus from "../assets/icons/Plus.svg";
import Minus from "../assets/icons/Minus.svg";
import Close from "../assets/icons/Close.svg";
import Go from "../assets/icons/go.svg";
import Category from "../assets/icons/category.svg";

export default {
  Go,
  Category,
  Edit,
  Bin,
  Love,
  Cart,
  Eye,
  Search,
  ArrowLeft,
  ArrowRight,
  ArrowRight2,
  StarActive,
  Star,
  DropDown,
  Plus,
  Minus,
  Close,
};
