import React, { useEffect, useRef, useState } from "react";
import { Icons, Images } from "../../constants";
import CircleButton from "../ui/CircleButton";
import uploadService from "../../services/uploadService";
import { useQuery } from "react-query";
import BoxUploadImage from "./BoxUploadImage";

const UploadImages = ({
  setValue,
  getValues,
  selectedImages,
  setSelectedImages,
  uploadProgress,
  setUploadProgress,
  oneFile,
  nameImg = "icon",
}) => {
  const fileInputRef = useRef();
  const [imgsSelected, setImgsSelected] = useState(null);

  const handleDrop = (event) => {
    event.preventDefault();

    const files = event.dataTransfer.files[0];
    uploadImage(files);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDelete = (index) => {
    setSelectedImages(null);
    setUploadProgress(null);
    setValue(nameImg, "");
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("myFile", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${process.env.REACT_APP_API_URL}/upload`, true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(progress);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log("Image uploaded successfully");
        if (xhr.responseText) {
          setValue(nameImg, JSON.parse(xhr.responseText).fileName);
        }
      } else {
        console.error("Image upload failed");
      }
    };

    xhr.send(formData);
  };

  const handleFileInputClick = (event) => {
    event.target.value = null;
  };

  const handleFileInputChange = (event) => {
    let files = event.target.files[0];

    setSelectedImages(files);
    uploadImage(files);
  };

  return (
    <div>
      <div className="space-y-2">
        {selectedImages ? (
          <div>
            <BoxUploadImage
              file={selectedImages}
              progress={uploadProgress ? uploadProgress : 0}
              handleDelete={handleDelete}
              imageNameInStorage={getValues().images}
            />
          </div>
        ) : (
          <div
            className={`drop-zone flex items-center justify-center flex-col border-2 border-dashed p-10 mb-4`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <img src={Images.Upload} alt="upload image" className="w-36" />
            <h1 className="font-medium mt-4">
              Drop you image here, or{" "}
              <span
                className="text-primary-100 cursor-pointer"
                onClick={() => fileInputRef.current.click()}
              >
                browse
              </span>
              <input
                type="file"
                multiple
                onChange={handleFileInputChange}
                onClick={handleFileInputClick}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
            </h1>
            <p className="text-sm text-gray-400">supports: JPG, JPEG, PNG</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImages;
