import React, { useState } from "react";
import { Icons } from "../../constants";
import InputCustom from "../ui/InputCustom";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

const SearchForm = ({ onSubmit }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");

  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      search: query ?? "",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <InputCustom
        icon={Icons.Search}
        nameIcon={"icon search"}
        placeholder={"search..."}
        css={"bg-white"}
        field={register("search")}
        showBtn
      />
    </form>
  );
};

export default SearchForm;
