import React, { useEffect, useState } from "react";
import InputCustom from "../ui/InputCustom";
import { Select } from "@chakra-ui/react";
import productsService from "../../services/productsService";
import categoriesService from "../../services/categoriesService";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import newsService from "../../services/newsService";
import UploadImages from "../ui/UploadImages";
import { Spinner } from "@chakra-ui/react";

const NewsAddForm = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);

  // add new data
  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      image: "",
    },
  });

  const onSubmit = (payload) => {
    setLoading(true);
    newsService
      .add(sessionStorage.getItem("token"), { ...payload })
      .then((res) => {
        toast({
          title: "New created.",
          description: "We've created your news for you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        reset();
        setSelectedImages(null);
        setUploadProgress(null);
        setLoading(false);
      })
      .catch((err) => {
        toast({
          title: "Error news created.",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <div className="bg-white rounded-lg p-6">
      <h3 className="capitalize text-xl text-secondary-100">
        ajouter nouveau news
      </h3>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="gap-4 mt-4 grid grid-cols-2"
      >
        <div className="space-y-2">
          <label htmlFor="" className="text-sm capitalize text-gray-500">
            titre en arabe
          </label>
          <InputCustom
            placeholder={"saisir le titre"}
            field={register("titleAR", {
              required: true,
            })}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="" className="text-sm capitalize text-gray-500">
            titre en francais
          </label>
          <InputCustom
            placeholder={"saisir le titre"}
            field={register("titleFR", {
              required: true,
            })}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="" className="text-sm capitalize text-gray-500">
            titre en anglais
          </label>
          <InputCustom
            placeholder={"saisir le titre"}
            field={register("titleEN", {
              required: true,
            })}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="" className="text-sm capitalize text-gray-500">
            la date d'event
          </label>
          <InputCustom
            placeholder={"saisir la date"}
            type="date"
            field={register("dateEvent", {
              required: true,
            })}
          />
        </div>

        <div className="col-span-2 space-y-2">
          <label htmlFor="" className="text-sm capitalize text-gray-500">
            description en arabe
          </label>
          <textarea
            className="outline-none w-full h-[150px] py-[7px] px-[12px] rounded-xl flex items-center justify-between border-gray-200 border"
            placeholder={"ecrire une description"}
            {...register("despAR", { required: true })}
          />
        </div>
        <div className="col-span-2 space-y-2">
          <label htmlFor="" className="text-sm capitalize text-gray-500">
            description en francais
          </label>
          <textarea
            className="outline-none w-full h-[150px] py-[7px] px-[12px] rounded-xl flex items-center justify-between border-gray-200 border"
            placeholder={"ecrire une description"}
            {...register("despFR", { required: true })}
          />
        </div>
        <div className="col-span-2 space-y-2">
          <label htmlFor="" className="text-sm capitalize text-gray-500">
            description en anglais
          </label>
          <textarea
            className="outline-none w-full h-[150px] py-[7px] px-[12px] rounded-xl flex items-center justify-between border-gray-200 border"
            placeholder={"ecrire une description"}
            {...register("despEN", { required: true })}
          />
        </div>
        <div className="col-span-2 space-y-2">
          <label htmlFor="" className="text-sm capitalize text-gray-500">
            images
          </label>
          <UploadImages
            nameImg="image"
            setValue={setValue}
            getValues={getValues}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            uploadProgress={uploadProgress}
            setUploadProgress={setUploadProgress}
          />
        </div>

        <div className="col-span-2 space-x-4 flex items-center justify-end pt-10">
          <Link
            to={"../"}
            className="cursor-pointer bg-secondary-100 rounded-lg px-8 py-2 text-white font-medium capitalize"
          >
            cancel
          </Link>
          {loading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <input
              type="submit"
              value="ajouter"
              className="cursor-pointer bg-primary-100 rounded-lg px-8 py-2 text-white font-medium capitalize"
            />
          )}
        </div>
      </form>
    </div>
  );
};

export default NewsAddForm;
