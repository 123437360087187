import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useToast,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Switch } from "@chakra-ui/react";
import { Icons } from "../../constants";
import { useQuery } from "react-query";
import categoriesService from "../../services/categoriesService";
import { Spinner } from "@chakra-ui/react";

const CategoriesTable = () => {
  const keys = ["icon", "name AR", "name FR", "name EN"];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState(null);
  const toast = useToast();

  // get query
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");

  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);

  // load data of categories
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "categories",
    () =>
      categoriesService.getAll(sessionStorage.getItem("token"), {
        query: query,
      })
  );

  useEffect(() => {
    refetch();
  }, [query]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  // conver date to format string
  const convertDateFormat = (date) => {
    const d = new Date(date);
    const formattedDate = d
      .toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
      .replace(",", "");

    return formattedDate;
  };

  // delete item
  const handleDelete = () => {
    setLoading(true);
    categoriesService
      .remove(sessionStorage.getItem("token"), selected)
      .then((res) => {
        toast({
          title: "Category deleted.",
          description: "We've deleted your category for you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refetch();
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error category deleted.",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      });
  };
  return (
    <div>
      <TableContainer className="w-full">
        <Table variant="simple" className="w-full">
          <Thead>
            <Tr className="text-left uppercase text-xs font-semibold">
              <Th className="">#</Th>
              {keys.map((key) => (
                <Th key={key}>{key}</Th>
              ))}
              <Th className=" ">créé à</Th>
              <Th isNumeric className="text-right ">
                <p className="mb-2 mr-2">...</p>
              </Th>
            </Tr>
          </Thead>
          <Tbody className="text-xs 2xl:text-sm">
            {data.map((item, idx) => {
              return (
                <Tr className="border-t border-gray-500" key={item.id}>
                  <Td>{idx + 1}</Td>
                  <Td className="py-3">
                    <div className="flex items-center border h-10 w-10 rounded-full overflow-hidden">
                      <img
                        src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${item.icon}`}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  </Td>
                  <Td>
                    <p className="truncate w-[100px] col-span-2">
                      {item.nameAR}
                    </p>
                  </Td>
                  <Td>
                    <p className="truncate w-[100px] col-span-2">
                      {item.nameFR}
                    </p>
                  </Td>
                  <Td>
                    <p className="truncate w-[100px] col-span-2">
                      {item.nameEN}
                    </p>
                  </Td>
                  <Td>
                    <p>{convertDateFormat(item.createdAt)}</p>
                  </Td>
                  <Td>
                    <div className="buttons flex justify-end items-center space-x-3">
                      <Link to={`./${item.id}`}>
                        <button className="bg-secondary-100 rounded-full w-[30px] h-[30px] flex items-center justify-center">
                          <img src={Icons.Edit} alt="" className="w-[17px]" />
                        </button>
                      </Link>
                      <button
                        className="bg-secondary-100 rounded-full w-[30px] h-[30px] flex items-center justify-center"
                        onClick={() => {
                          setSelected(item.id);
                          onOpen();
                        }}
                      >
                        <img src={Icons.Bin} alt="" className="w-[17px]" />
                      </button>
                    </div>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <AlertBox
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
        loading={loading}
      />
    </div>
  );
};

export default CategoriesTable;

const AlertBox = ({ isOpen, onClose, handleDelete, loading }) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      // leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Category
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          {loading ? (
            <AlertDialogFooter className="space-x-4">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </AlertDialogFooter>
          ) : (
            <AlertDialogFooter className="space-x-4">
              <button
                onClick={onClose}
                className="border border-third-100 text-third-100 font-medium px-6 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="bg-third-100 text-white font-medium px-6 py-2 rounded-lg"
              >
                Delete
              </button>
            </AlertDialogFooter>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
