import React from "react";
import CircleButton from "./CircleButton";
import { Icons } from "../../constants";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from "@chakra-ui/react";
import uploadService from "../../services/uploadService";

const BoxUploadImage = ({
  file,
  progress,
  handleDelete,
  imageNameInStorage,
  show = false,
}) => {
  if (show)
    return (
      <div className="border px-4 py-2 rounded-lg">
        <div className="flex items-center justify-between">
          <div className="flex space-x-4">
            <div className="w-12 h-12 object-cover rounded-lg border flex items-center justify-center">
              <img
                src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${imageNameInStorage}`}
                alt="image"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <h3 className="font-medium capitalize">already exist</h3>
              <div className="flex items-center text-sm text-gray-400 space-x-2">
                <p>{file}</p>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <CircleButton
              icon={Icons.Bin}
              bg="bg-third-100"
              onClick={handleDelete}
            />
          </div>
        </div>
      </div>
    );
  return (
    <div className="border px-4 py-2 rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex space-x-4">
          {progress === 100 && imageNameInStorage && (
            <div className="w-12 h-12 object-cover rounded-lg border flex items-center justify-center">
              <img
                src={`${process.env.REACT_APP_UPLOADS_URL}/uploads/${imageNameInStorage}`}
                alt="image"
                className="w-full h-full object-cover"
              />
            </div>
          )}
          <div>
            <h3 className="font-medium capitalize">
              {progress === 100 ? "completed" : "uploading..."}
            </h3>
            <div className="flex items-center text-sm text-gray-400 space-x-2">
              {progress < 100 && (
                <div className="flex items-center space-x-2">
                  <p>{progress} %</p>
                  <p>-</p>
                </div>
              )}
              <p>{file.name}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          {progress < 100 ? (
            <CircleButton
              icon={Icons.Close}
              bg="bg-third-100 bg-opacity-30"
              onClick={handleDelete}
            />
          ) : (
            <CircleButton
              icon={Icons.Bin}
              bg="bg-third-100"
              onClick={handleDelete}
            />
          )}
        </div>
      </div>
      {progress < 100 && (
        <Slider aria-label="slider-ex-1" value={progress}>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
        </Slider>
      )}
    </div>
  );
};

export default BoxUploadImage;
