import React from "react";
import PopoverCustom from "../components/ui/PopoverCustom";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Icons, Logos } from "../constants";

const SideBar = () => {
  const navigate = useNavigate();
  return (
    // <div className="bg-white min-w-[250px] 2xl:min-w-[300px] h-full text-gray-700 fixed">
    <div className="bg-white w-full h-full text-gray-700">
      {/* <div className="fixed top-0 left-0 min-w-[250px] 2xl:min-w-[300px] bottom-0"> */}
      <img src={Logos.LogoName} alt="" className="w-40 mx-auto pt-10 mb-10" />
      <h3 className="text-sm capitalize px-6 mb-2">main</h3>
      <ul className="space-y-14 mb-8 text-base">
        <NavLink to={"/categories"}>
          <div className="flex items-center px-6 hover:bg-secondary-100 hover:bg-opacity-50 space-x-2">
            <img src={Icons.Category} alt="" className="w-4" />
            <li className="flex items-center justify-between w-full font-medium py-3">
              Gestion Categories
            </li>
          </div>
        </NavLink>
        {/* <NavLink to={"/startups"}>
          <div className="flex items-center px-6 hover:bg-secondary-100 hover:bg-opacity-50 space-x-2">
            <img src={Icons.Category} alt="" className="w-4" />
            <li className="flex items-center justify-between w-full font-medium py-3">
              Gestion Startups
            </li>
          </div>
        </NavLink> */}
        <NavLink to={"/news"}>
          <div className="flex items-center px-6 hover:bg-secondary-100 hover:bg-opacity-50 space-x-2">
            <img src={Icons.Category} alt="" className="w-4" />
            <li className="flex items-center justify-between w-full font-medium py-3">
              Gestion News
            </li>
          </div>
        </NavLink>
        <NavLink to={"/talks"}>
          <div className="flex items-center px-6 hover:bg-secondary-100 hover:bg-opacity-50 space-x-2">
            <img src={Icons.Category} alt="" className="w-4" />
            <li className="flex items-center justify-between w-full font-medium py-3">
              Gestion Talks
            </li>
          </div>
        </NavLink>
        <NavLink to={"/products"}>
          <li className="flex items-center justify-between w-full font-medium py-2 hover:bg-secondary-100 hover:bg-opacity-50 px-6">
            logout
          </li>
        </NavLink>
      </ul>
    </div>
    // </div>
  );
};

export default SideBar;
