import React from "react";

const InputCustom = ({
  icon,
  nameIcon,
  placeholder,
  css,
  field,
  defaultValue = "",
  type = "text",
  showBtn = false,
}) => {
  return (
    <div
      className={`py-[7px]  rounded-lg ${css} relative flex items-center justify-center border-gray-200 border`}
    >
      <input
        type={type}
        placeholder={placeholder}
        name="search"
        className={`bg-transparent ${
          showBtn ? "pr-14 pl-4" : "px-4"
        } outline-none !w-full`}
        {...field}
        defaultValue={defaultValue}
      />
      {showBtn && (
        <button
          type="submit"
          className="absolute right-4 top-1/2 -translate-y-1/2"
        >
          <img src={icon} alt={nameIcon} className="w-5" />
        </button>
      )}
    </div>
  );
};

export default InputCustom;
