import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useToast,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Switch } from "@chakra-ui/react";
import { Icons } from "../../constants";
import { useQuery } from "react-query";
import categoriesService from "../../services/categoriesService";
import SearchForm from "../form/SearchForm";
import coursesService from "../../services/newsService";
import FilterBar from "../../layout/FilterBar";
import { Select } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import talksService from "../../services/talksService";

const TalksTable = () => {
  const keys = ["name", "email", "phone"];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState(null);
  const toast = useToast();

  // get query
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("query");

  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);

  // go to another page
  const navigate = useNavigate();

  // load data of courses
  const { isLoading, data, isError, error, isFetching, refetch } = useQuery(
    "talks",
    () =>
      talksService.getAll(sessionStorage.getItem("token"), {
        query: query,
      })
  );

  useEffect(() => {
    refetch();
  }, [query]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  // conver date to format string
  const convertDateFormat = (date) => {
    const d = new Date(date);
    const formattedDate = d
      .toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
      .replace(",", "");

    return formattedDate;
  };

  return (
    <div>
      <TableContainer className="w-full">
        <Table variant="simple" className="w-full">
          <Thead>
            <Tr className="text-left uppercase text-xs font-semibold">
              <Th className="">#</Th>
              {keys.map((key) => (
                <Th key={key}>{key}</Th>
              ))}
              <Th className=" ">créé à</Th>
              <Th className=" ">details</Th>
            </Tr>
          </Thead>
          <Tbody className="text-sm">
            {data.map((item, idx) => {
              return (
                <Tr className="border-t border-gray-500 h-fit" key={item.id}>
                  <Td>{idx + 1}</Td>
                  <Td>
                    <p className="truncate w-[100px] col-span-2">{item.name}</p>
                  </Td>
                  <Td>
                    <p className="truncate w-[100px] col-span-2">
                      {item.email}
                    </p>
                  </Td>
                  <Td>
                    <p className="truncate w-[100px] col-span-2">
                      {item.phone}
                    </p>
                  </Td>
                  <Td>
                    <p>{convertDateFormat(item.createdAt)}</p>
                  </Td>
                  <Td>
                    <button
                      className="bg-secondary-100 rounded-full w-[30px] h-[30px] flex items-center justify-center"
                      onClick={() => {
                        setSelected(item);
                        onOpen();
                      }}
                    >
                      <img src={Icons.Eye} alt="" className="w-[17px]" />
                    </button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        {selected && (
          <ModalDetails
            isOpen={isOpen}
            onClose={() => {
              onClose();
              setSelected(null);
            }}
            selected={selected}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default TalksTable;

const ModalDetails = ({ isOpen, onClose, selected }) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      // leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Detail Message
          </AlertDialogHeader>

          <AlertDialogBody>
            <div className="">
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">name:</p>
                <p className="col-span-2">{selected.name}</p>
              </div>
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">email:</p>
                <p className="col-span-2">{selected.email}</p>
              </div>
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">phone:</p>
                <p className="col-span-2">{selected.phone}</p>
              </div>
              <div className="grid grid-cols-3">
                <p className="font-semibold first-letter:capitalize">
                  content:
                </p>
                <p className="col-span-2">{selected.content}</p>
              </div>
            </div>
          </AlertDialogBody>
          <AlertDialogFooter className="space-x-4">
            <button
              onClick={onClose}
              className="border border-third-100 text-third-100 font-medium px-6 py-2 rounded-lg"
            >
              Fermer
            </button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
